<template>
  <!-- <c-step
    stepperGrpCd="HEA_CHECKUP_STEP_CD"
    :currentStepCd="popupParam.stepCd"
    :param="popupParam"
    v-model="popupParam.stepCd"
    @currentStep="currentStep"
    >
  </c-step> -->
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <chem-components
          :is="tab.component"
          :param="popupParam"
          :data.sync="data"
          :count="count"
          @changeStatus="changeStatus"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checkup-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        checkupTypeCd: null,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'plan',
      tabItems: [
        { name: 'plan', icon: 'edit', label: '건강검진 계획 및 대상자 관리', component: () => import(`${'./checkUpPlan.vue'}`) },
      ],
      addTabItems1: [
        { name: 'status', icon: 'location_city', label: '검진예약 현황', component: () => import(`${'./checkUpReserveStatus.vue'}`) },
        { name: 'result', icon: 'assignment', label: '건강검진 결과', component: () => import(`${'./checkUpResult.vue'}`) },
      ],
      addTabItems2: [
        { name: 'result', icon: 'assignment', label: '건강검진 결과', component: () => import(`${'./checkUpResult.vue'}`) },
      ],
      data: {
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        checkupStartDate: '',
        checkupEndDate: '',
        checkupDateArray: [],
        regUserId: '',
        chgUserId: '',
        checkupResultModelList: [],
        deleteCheckupResultModelList: [],
      },
      getUrl: '',
      count: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.hea.checkup.plan.get.url;
      if (this.popupParam.heaCheckupPlanId) {
        if (this.popupParam.checkupTypeCd === 'CUTC000001') {
          this.tabItems = this.tabItems.concat(this.addTabItems1);
        } else if (this.popupParam.checkupTypeCd === 'CUTC000002') {
          this.tabItems = this.tabItems.concat(this.addTabItems2);
        }
      }
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.heaCheckupPlanId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heaCheckupPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
          this.count ++;
          if (this.data.checkupStartDate && this.data.checkupEndDate) {
            this.data.checkupDateArray = [this.data.checkupStartDate, this.data.checkupEndDate];
          }
        },);
      } 
    },
    changeStatus(_heaCheckupPlanId, _checkupTypeCd) {
      this.popupParam.heaCheckupPlanId = _heaCheckupPlanId;
      if (_checkupTypeCd === 'CUTC000001') {
        this.tabItems = this.tabItems.concat(this.addTabItems1);
      } else if (_checkupTypeCd === 'CUTC000002') {
        this.tabItems = this.tabItems.concat(this.addTabItems2);
      }
      this.getDetail();
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>